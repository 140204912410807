<template>
  <div class="full">
    <nav class="order_nav d_f ali_c">
      <img src="../img/black_back.png" alt="" class="cur_p" @click="$router.go(-1)">
      <span>费用明细</span>
    </nav>
    <section class="ed_content">
      <div class="title d_f ali_b">
        <span class="ed_title" v-if="discountAmount > 0">实际费用</span>
        <span class="ed_title" v-else>订单总额</span>
        <span class="sub">在线支付 <span class="red">￥{{(totalPrice) / 100}}</span></span>
      </div>
      <div class="reallymoney" v-show="discountAmount > 0">
        <div class="item">
          <span>订单总额</span><span>￥{{totalPrice/100}}</span>
        </div>
        <div class="item">
          <span>消费劵抵扣</span><span>-￥{{discountAmount/100}}</span>
        </div>
      </div>
      <div class="edc_item">
        <div class="item" v-for="(item,index) in detailList" :key="index">
          <span>{{item.nowDate}}</span>
          <span>{{item.zc}}</span>
          <span v-show="discountAmount > 0 && index === 0"><div class="jfdk" v-if="index === 0">消费劵抵扣 减{{discountAmount / 100}}</div></span>
          <div class="money" v-if="discountAmount > 0">
            <p>￥{{item.sellPrice / 100}}</p>
            <h1 v-if="index === 0">￥{{(item.sellPrice - discountAmount)/100}}</h1>
            <h1 v-else>￥{{(item.sellPrice)/100}}</h1>
          </div>
          <span v-else>￥{{item.sellPrice ? item.sellPrice / 100 : 0}}</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import apis from '../utils/apis'

  export default {
    name: "expenseDetails",
    data() {
      return {
        priceQuery:{
          roomId:this.$route.query.roomId,
          startTime:this.$route.query.startTime,
          endTime:this.$route.query.endDate,
        },
        roomNum:this.$route.query.num,
        totalPrice:0,
        orderNumber:this.$route.query.orderNumber, // 订单编号
        totalDiscountAmount: 0,
        stayDateList: [], // 停留日期列表
        orderDetail: [], // 当地详情
        detailList: [] // 每日详细信息
      }
    },
    created(){
      // this.queryPriCalendarByRoomIdAndDate();
      this.findHotelOrderDetail()
    },
    methods: {
      // 订单详情
      findHotelOrderDetail(){
        let _t = this;
        this.detailList = []
        _t.$get({
          url:apis.findHotelOrderDetail,
          params: {
            orderNumber: _t.orderNumber,
            token:_t.$global.token,
          }
        }).then(res => {
          _t.orderDetail = res;
          _t.discountAmount = res[0].discountAmount;
          let totalPrice = 0, totalDiscountAmount = 0, stayDateList = []; // 订单总金额 积分抵扣金额 停留日期列表
          res.map( item => {
            totalPrice += item.sellPrice
            totalDiscountAmount += item.totalDiscountAmount
            if(stayDateList.indexOf(item.beginDate) == -1){
              stayDateList.push(item.beginDate)
            }
          })
          _t.totalPrice = totalPrice
          _t.totalDiscountAmount = totalDiscountAmount
          _t.stayDateList = stayDateList
          _t.queryPriCalendarByRoomIdAndDate()
        });
      },
      getRoomInfo(row,index){
        let _t = this;
        this.$get({
          url: apis.findHotelRoom,
          params: {
            roomId:row.roomId,
            beginTime:row.stockDate,
            endTime:row.stockDate,
          },
        }).then(rsp => {
          let str = rsp.breakfast > 0 ? '(含早)' : '';
          let nowDate = _t.stayDateList[index], zc = '房费' + str, totalDiscountAmount = 0, sellPrice = 0;
          _t.orderDetail.map( item => {
            if(nowDate == item.beginDate){
              sellPrice += item.listedPrice
              totalDiscountAmount += item.totalDiscountAmount
            }
          })
          _t.detailList.push({nowDate: nowDate, zc: zc, totalDiscountAmount: totalDiscountAmount, sellPrice: rsp.minSellPrice})
        });
      },
      //查询费用明细
      queryPriCalendarByRoomIdAndDate(){
        let _t = this;
        _t.$get({
          url:apis.queryPriCalendarByRoomIdAndDate,
          params: _t.priceQuery
        }).then(res => {
          res.map((item,index) => {
            _t.getRoomInfo(item,index)
          });
        });
      },
    }
  }
</script>

<style scoped lang="scss">
  .edc_item{
    font-size:13px;
    font-weight:500;
    color:rgba(144,144,144,1);
    margin-top: 20px;
    .ei_title{
      width: 106px;
    }
    >div{
      padding: 15px 0;
      &:not(:last-child){
        border-bottom: 1px solid #D0D0D0;
      }
    }
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      .jfdk{
        display: inline-block;
        border: 1px solid #E93411;
        color: #E93411;
        border-radius: 15px;
        padding: 2px 10px;
        font-size: 10px;
        vertical-align: text-top;
        margin-right: 10px;
      }
      .money {
        font-size: 15px;
        p{
          text-decoration: line-through;
        }
        h1{
          font-size: 15px;
          color: #666;
        }
      }
    }
  }
  .title{
    justify-content: space-between;
    align-items: center;
    .ed_title{
      width: 106px;
    }
    margin-bottom: 25px;
    font-size:17px;
    font-weight:500;
    color:rgba(62,62,62,1);
    .sub{
      font-weight:500;
      color:rgba(62,62,62,1);
      .red{
        color: #E10000;
        font-size: 17px;
      }
    }
  }
  .order_nav{
    background: white;
    span{
      color: #3E3E3E!important;
    }
  }
  .ed_content{
    padding: 22px 21px;
  }
  .full{
    background-color: #F0F0F0;
    box-sizing: border-box;
  }
  .reallymoney{
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 30px;
    }
  }
</style>
